@media screen and (orientation: landscape){
  .microsofttocustomer{
    float: left;
    border: 1px; 
    padding: 0px;
    margin: 0px;
    line-height: 100%;
  }
}
@media screen and (orientation: landscape){
  .partnertocustomer{
    float: left;
  }
}
h5 {
  border: 1px; 
  padding: 0px;
  margin: 0px;
  line-height: 100%;
}
